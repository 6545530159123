import logo from './logo.svg';
import './App.css';
import Home from './Components/Home/Home';
import { BrowserRouter,Routes,Route, Navigate } from "react-router-dom";
import { IdProvider } from "./Components/Home/IdContext.js";
import AboutUs from './Components/Footer/AboutUs.js';
import ContactUs from './Components/Footer/ContactUs.js';
import Careers from './Components/Footer/Careers/Careers.js';
import Internship from './Components/Footer/Careers/Internship.js';
import Jobs from './Components/Footer/Careers/Jobs.js';
import Faqs from './Components/Footer/Faqs.js';
import TermsAndConditions from './Components/Footer/TermsAndConditions.js';
import ComingSoon from './Components/Header/ComingSoon.js';
import AiContent from './Components/Home/AiContent.js';

function App() {
  return (
<BrowserRouter>
    <IdProvider>
      <Routes>
        <Route path='/home' element={<Home/>}></Route>
        <Route path='/about-us' element={<AboutUs/>}/>
        <Route path='/contact-us' element={<ContactUs/>}/>
        <Route path='/careers' element={<Careers/>}/>
        <Route path='/careers-internship' element={<Internship/>}/>
        <Route path='/careers-jobs' element={<Jobs/>}/>
        <Route path='/faqs' element={<Faqs/>}/>
        <Route path='/terms-and-conditions' element={<TermsAndConditions/>}/>
        <Route path='/login' element={<ComingSoon/>}/>
        <Route path='/ai-content' element={<AiContent/>}/>

        <Route path="*" element={<Navigate to="/home" />} />
       </Routes>

    </IdProvider>

    </BrowserRouter>
  );
}

export default App;

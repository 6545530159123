import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box, Grid, IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import axios from 'axios';
import ContactUsBackground from '../Icon/Background2.png';
import Footer from './Footer';
import Header from '../Header/Header';
import './ContactUs.css';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const handlePopState = () => {
      navigate('/home');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (formData.name.length > 20) newErrors.name = 'Name must be 20 characters or less.';
    if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid.';
    if (!/^\d{10}$/.test(formData.contact)) newErrors.contact = 'Contact number must be exactly 10 digits.';
    if (formData.message.length > 255) newErrors.message = 'Message must be 255 characters or less.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await axios.post('http://88.222.212.226:8000/create_query/', formData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        setFormData({ name: '', email: '', contact: '', message: '' });
        alert('Your message has been submitted successfully!');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'black' }}>
      <Header />
      <Box
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: { xs: '16px', sm: '24px', md: '32px' },
          color: 'white',
          mt: { xs: 10, sm: 12, md: 15 },
          mb: { xs: 6, sm: 8 },
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <form onSubmit={handleSubmit} style={{ background: 'black', padding: '16px', borderRadius: '8px', border: '2px solid #6495ED' }}>
              <Typography variant="h4" component="h1" gutterBottom textAlign="center" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                Contact Us
              </Typography>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  margin="normal"
                  error={!!errors.name}
                  helperText={errors.name}
                  InputLabelProps={{ style: { color: 'white' } }}
                  InputProps={{ style: { color: 'white' } }}
                  sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  margin="normal"
                  error={!!errors.email}
                  helperText={errors.email}
                  InputLabelProps={{ style: { color: 'white' } }}
                  InputProps={{ style: { color: 'white' } }}
                  sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  name="contact"
                  type="tel"
                  value={formData.contact}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  margin="normal"
                  error={!!errors.contact}
                  helperText={errors.contact}
                  InputLabelProps={{ style: { color: 'white' } }}
                  InputProps={{ style: { color: 'white' } }}
                  sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  margin="normal"
                  error={!!errors.message}
                  helperText={errors.message}
                  multiline
                  rows={4}
                  InputLabelProps={{ style: { color: 'white' } }}
                  InputProps={{ style: { color: 'white' } }}
                  sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                />
              </Box>
              <Box sx={{ width: { xs: '100%', sm: '80%', md: '60%' }, margin: '0 auto' }}>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Submit
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
      <IconButton 
        onClick={scrollToTop} 
        sx={{ 
          position: 'fixed', 
          bottom: { xs: 16, md: 32 }, 
          right: { xs: 16, md: 32 }, 
          backgroundColor: 'white', 
          color: 'black' 
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
      <Footer />
    </div>
  );
}

export default ContactUs;

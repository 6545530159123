import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Box, Container, Typography } from '@mui/material';
import AI from '../Icon/AI.png'; // Import the AI image
import Slider from 'react-slick';

function AiContent() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div>
            <Header />

            {/* Box for the Background Image */}
            <Box
                sx={{
                    height: '100vh', // Set the height for the Box
                    backgroundImage: `url(${AI})`, // Set the background image
                    backgroundSize: '75%', // Reduce size to 75% of the box
                    backgroundPosition: 'center', // Center the image
                    backgroundRepeat: 'no-repeat', // Prevent repetition of the image
                    display: 'flex', // Optional: flex properties for content alignment
                    justifyContent: 'center',
                    alignItems: 'flex-start', // Align items at the top
                    position: 'relative', // Position relative for absolute children
                    zIndex: 1, // To layer it correctly
                }}
            >
                {/* Text positioned absolutely within the Box */}
                <Typography
                    variant="h1"
                    sx={{
                        position: 'absolute', // Position absolute to overlay on the image
                        top: '150px', // Distance from the top of the box
                        left: '50%', // Center horizontally
                        transform: 'translateX(-50%)', // Adjust for centering
                        zIndex: 2, // Ensure it is above the image
                        fontSize: '48px', // Font size for the heading
                        fontWeight: 'bold', // Bold text
                        color: '#fff', // Text color
                    }}
                >
                    Anthrasync AI
                </Typography>
            </Box>



            {/* <Typography variant="h1" sx={{ zIndex: 2, textAlign: 'center', fontSize: '48px', mb: 8 }}>
                Welcome to Anthrasync AI 
            </Typography> */}

            <Container sx={{ mb: 10, mt: 10 }}>
                <Box
                    sx={{
                        p: 4,
                        backgroundColor: '#1E1E1E',
                        color: '#fff',
                        borderRadius: '8px'
                    }}
                >
                    <Typography variant="h3" sx={{ mb: 2, textAlign: 'center', fontSize: '40px' }}>Join the AI-Powered Knowledge Revolution</Typography>
                    <Typography variant="body1" sx={{ fontSize: '20px' }}>
                        At AnthraSync, we believe that knowledge should be shared, not siloed. By harnessing the power of AI, we’re creating a future where information is accessible to everyone, empowering teams and individuals to thrive.
                    </Typography>
                </Box>
            </Container>

            {/* First Container for Introduction and Why AnthraSync */}
            {/* <Container sx={{ mb: 10 }}>
                <Box
                    sx={{
                        p: 4,
                        backgroundColor: '#1E1E1E',
                        color: '#fff',
                        mb: 4,
                        borderRadius: '8px',
                    }}
                >
                    <Typography variant="h2" sx={{ mb: 2, fontSize: '40px' }}> 
                        Welcome to AnthraSync
                    </Typography>
                    <Typography variant="body1">
                        At AnthraSync, we are on a mission to transform how organizations connect, share, and manage knowledge.
                        By leveraging cutting-edge AI technology, we simplify the process of information flow, helping teams and individuals work smarter, not harder.
                    </Typography>
                </Box>

                <Box
                    sx={{
                        p: 4,
                        backgroundColor: '#1E1E1E',
                        color: '#fff',
                        mb: 4,
                        borderRadius: '8px',
                    }}
                >
                    <Typography variant="h3" sx={{ mb: 2, fontSize: '40px' }}>Why AnthraSync?</Typography>
                    <Typography variant="body1">
                        In today’s fast-paced digital world, knowledge is power. However, managing and sharing that knowledge effectively can be a challenge.
                        AnthraSync provides an innovative AI-powered platform that breaks down barriers, ensuring that critical information is accessible when and where it’s needed most.
                    </Typography>
                </Box>
            </Container> */}

            <Box
                sx={{
                    p: 4,
                    color: '#fff',
                    mb: 4,
                    borderRadius: '8px',
                    textAlign: 'center'
                }}
            >
                <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2, fontSize: '40px' }}>
                    Our platform offers:
                </Typography>
            </Box>

            <Container sx={{ mb: 10, display: 'flex', gap: 2 }}>

                {/* Individual Offerings */}
                <Box
                    sx={{
                        p: 3,
                        backgroundColor: '#2E2E2E', // Slightly different shade for differentiation
                        color: '#fff',
                        mb: 2,
                        borderRadius: '8px',
                    }}
                >
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2, fontSize: '30px' }}>
                        Seamless Knowledge Connectivity
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '20px' }}>
                        AI-driven solutions that connect data and resources, making knowledge-sharing intuitive and effortless.
                    </Typography>
                </Box>

                <Box
                    sx={{
                        p: 3,
                        backgroundColor: '#2E2E2E', // Consistent shade
                        color: '#fff',
                        mb: 2,
                        borderRadius: '8px',
                    }}
                >
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2, fontSize: '30px' }}>
                        Smart Organization
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '20px' }}>
                        Automatically organize, filter, and recommend relevant information based on users' needs.
                    </Typography>
                </Box>

                <Box
                    sx={{
                        p: 3,
                        backgroundColor: '#2E2E2E', // Consistent shade
                        color: '#fff',
                        mb: 2,
                        borderRadius: '8px',
                    }}
                >
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2, fontSize: '30px' }}>
                        Collaboration Redefined
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '20px' }}>
                        Enhance teamwork with tools that streamline communication and knowledge flow across departments and teams.
                    </Typography>
                </Box>
            </Container>

            <Container sx={{ mb: 10 }}>
                {/* Header for Features */}
                <Typography variant="h3" sx={{ mb: 2, textAlign: 'center' }}>
                    Features that Set Us Apart
                </Typography>

                {/* Parent Box for Features */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between', // Adjust space between boxes
                        flexWrap: 'wrap', // Allows wrapping for responsiveness
                        mb: 4,
                    }}
                >
                    {/* Individual Feature Boxes */}
                    <Box
                        sx={{
                            p: 4,
                            backgroundColor: '#1E1E1E',
                            color: '#fff',
                            mb: 2,
                            borderRadius: '8px',
                            flex: '1 1 200px', // Flex properties for responsiveness
                            margin: '0 10px', // Optional: margin between boxes
                        }}
                    >
                        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2, fontSize: '30px' }}>
                            AI-Powered Insights
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '20px' }}>
                            Get intelligent recommendations and insights tailored to your work environment.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            p: 4,
                            backgroundColor: '#1E1E1E',
                            color: '#fff',
                            mb: 2,
                            borderRadius: '8px',
                            flex: '1 1 200px', // Flex properties for responsiveness
                            margin: '0 10px', // Optional: margin between boxes
                        }}
                    >
                        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2, fontSize: '30px' }}>
                            Easy Integration
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '20px' }}>
                            Our platform integrates effortlessly into your existing systems, ensuring a smooth transition.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            p: 4,
                            backgroundColor: '#1E1E1E',
                            color: '#fff',
                            mb: 2,
                            borderRadius: '8px',
                            flex: '1 1 200px', // Flex properties for responsiveness
                            margin: '0 10px', // Optional: margin between boxes
                        }}
                    >
                        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2, fontSize: '30px' }}>
                            Scalable for Growth
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '20px' }}>
                            Whether you’re a startup, SME, or large enterprise, our platform scales with your organization’s needs.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            p: 4,
                            backgroundColor: '#1E1E1E',
                            color: '#fff',
                            mb: 2,
                            borderRadius: '8px',
                            flex: '1 1 200px', // Flex properties for responsiveness
                            margin: '0 10px', // Optional: margin between boxes
                        }}
                    >
                        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2, fontSize: '30px' }}>
                            Security First
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '20px' }}>
                            We prioritize data privacy and security, keeping your intellectual assets safe.
                        </Typography>
                    </Box>
                </Box>
            </Container>

            <Box
                sx={{
                    p: 4,
                    backgroundColor: '#1E1E1E',
                    color: '#fff',
                    mb: 4, // Margin below the box
                    borderRadius: '8px',
                    maxWidth: '600px', // Set a maximum width for the slider
                    margin: '0 auto', // Center the slider horizontally
                }}
            >
                <Typography variant="h3" sx={{ mb: 2, textAlign: 'center', fontSize: '40px' }}>Who Can Benefit?</Typography>
                <Slider {...settings}>
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="body1" sx={{ fontSize: '20px' }}>
                            Startups and SMEs looking to optimize their internal knowledge flow.
                        </Typography>
                    </Box>
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="body1" sx={{ fontSize: '20px' }}>
                            Educational Institutions aiming to enhance learning experiences with smarter content sharing.
                        </Typography>
                    </Box>
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="body1" sx={{ fontSize: '20px' }}>
                            Large Enterprises seeking efficient knowledge management solutions across various departments.
                        </Typography>
                    </Box>
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="body1" sx={{ fontSize: '20px' }}>
                            Tech Enthusiasts who want to stay at the forefront of AI and knowledge-driven innovation.
                        </Typography>
                    </Box>
                </Slider>
            </Box>

            <Footer />
        </div>
    );
}

export default AiContent;

import React from 'react'

function Internship() {
  return (
    <div>
      Internship
    </div>
  )
}

export default Internship

import React from 'react';
import Logo from '../Logo/Dark/Full - Copy/1Asset 17@72x-8.png';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

function Header() {
  return (
    <Box 
      display="flex" 
      justifyContent="space-between" 
      alignItems="center" 
      sx={{ 
        padding: { xs: '5px 10px', sm: '5px 15px', md: '10px 20px' }, // Reduced padding for a thinner header
        position: 'fixed', 
        top: 0, 
        left: 0,
        right: 0,
        backgroundColor: 'black', 
        zIndex: 1000, 
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Optional shadow for better visibility
        borderBottom: '1px solid #1E90FF', // Thinner bottom border
        gap: 1
      }}
    >
      <Link to="/home">
        <Box 
          sx={{
            display: 'flex',
            alignItems: 'center',
            transition: 'transform 0.3s', // Smooth transition for scaling
            '&:hover': {
              transform: 'scale(1.1)', // Expand box on hover
            }
          }}
        >
          <img 
            src={Logo} 
            alt="Logo" 
            style={{ width: '120px', height: 'auto', maxWidth: '100%' }} // Smaller logo for thinner header
          />
        </Box>
      </Link>

      <Box 
        display="flex" 
        flexDirection="row" // Keep buttons in a row
        alignItems="center"
      >
        <Link 
          to="/login" 
          style={{ 
            textDecoration: 'none', 
            marginRight: '10px' // Add gap between buttons
          }}
        >
          <Button 
            variant="outlined" 
            size="small" 
            sx={{
              transition: 'transform 0.3s', // Smooth transition for scaling
              '&:hover': {
                transform: 'scale(1.1)', // Expand button on hover
              }
            }}
          >
            Login
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

export default Header;

import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, Container, Button, Grid, Card, AppBar, Toolbar, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import LogoIcon from '../Icon/v6.mp4'; // Ensure this video file is accessible
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Teach from '../Icon/Teach.png';
import Learn from '../Icon/Learn4.png';
import Monetize from '../Icon/Monitize2.png';
import Growth from '../Icon/Growth.png';
import Announcement from '../Icon/Announce3.png';
import Collaborative from '../Icon/Collaborate2.png';
import Creative from '../Icon/Creative.png';
import AI from '../Icon/AI.png';
import './Home.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import Real_world from '../Icon/Real_world.png';
import Practical_skill from '../Icon/Practical_skill.png';
import Learning from '../Icon/Learning.png';
import Time from '../Icon/Time.png';
import VisionBackground from '../Icon/Vission_latest.png';
import MissionBackground from '../Icon/mi1.png';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton } from '@mui/material';
import './WelcomeAnimation.css';


function Home() {
    const logoIconRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0); // State to track the active box


    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };
    // const phrase = "Anthrasync Welcomes You!";
    // const words = ["Anthrasync", "Welcomes", "You!"];

    // const renderLetters = (word) => {
    //     return word.split("").map((char, index) => (
    //         <Typography
    //             key={index}
    //             variant="h3"
    //             className="letter"
    //             style={{ animationDelay: `${index * 0.1}s` }} // Delay for each letter
    //         >
    //             {char}
    //         </Typography>
    //     ));
    // };

    const navigate = useNavigate(); // Initialize navigate

    const handleLearnMoreClick = () => {
        navigate('/home'); // Replace '/content' with the path to your content page
    };


    useEffect(() => {
        // Add animation logic here if needed
    }, []);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1, // Show one box at a time
        slidesToScroll: 1,
        arrows: false, // You can set to true if you want navigation arrows
        afterChange: (current) => {
            console.log("Current slide: ", current);
        },
        // Add custom CSS for the 'floating wave' effect
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const features = [
        {
            title: 'Teach',
            description: "Teaching what you are passionate about in language of your choice can be incredibly rewarding.",
            image: Teach,
        },
        {
            title: 'Learn',
            description: "Learn what you want in language of your choice at your own pace and time.",
            image: Learn,
        },
        {
            title: 'Monetize',
            description: "Monetizing your knowledge can open up new doors of opportunities and enable financial freedom.",
            image: Monetize,
        },
        {
            title: 'Connect',
            description: "You can connect with like-minded individuals based on your interests, hence become part of thriving community.",
            image: Collaborative,
        },
        {
            title: 'Grow',
            description: "Sharing your knowledge helps others and reinforces your own understanding.",
            image: Growth,
        },
        {
            title: 'Crucial Learning',
            description: "Learning through hands-on experiences and real-life examples is crucial for personal growth.",
            image: Creative,
        },]

    const benefits = [
        {
            title: "Real-World Info",
            description: "Learn from people who have hands-on experience, not just theoretical knowledge.",
            image: Real_world, // Add an image for each benefit
        },
        {
            title: "Utilise Time",
            description: "Direct mentorship and personalised learning paths eliminate unnecessary internet searching.",
            image: Time,
        },
        {
            title: "Be Practical",
            description: "Focussing on what works in the real world, makes you more capable in your field.",
            image: Practical_skill,
        },
        {
            title: "Passion-Driven",
            description: "Bring your passion to life. As a passionate educator you can offer more engaging and valuable sessions.",
            image: Learning,
        },
    ];

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        appendDots: dots => (
            <div
                style={{
                    position: 'absolute',
                    bottom: window.innerWidth < 600 ? '-20px' : window.innerWidth < 960 ? '-25px' : '-30px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <ul style={{
                    margin: '0px',
                    padding: '0px',
                    display: 'flex',
                    gap: window.innerWidth < 600 ? '1px' : '5px' // Smaller gap for small screens
                }}>
                    {dots}
                </ul>
            </div>
        ),

        customPaging: () => (
            <div
                style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    opacity: 0.5,
                }}
            />
        )
    };

    return (
        <div>
            <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
            <div style={{
                backgroundColor: 'black',
                height: '100%',
                minHeight: '100vh',
                position: 'fixed',
                width: '100%',
                zIndex: -1,
            }}></div>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: {
                        xs: '60vh',
                        sm: '80vh',
                        md: '90vh',
                        lg: '90vh',
                        xl: '90vh',
                    },
                    position: 'relative',
                    zIndex: 1,
                    textAlign: 'center',
                    overflow: 'hidden',
                    mb: 12,
                }}
            >
                <Box
                    ref={logoIconRef}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {
                            xs: '80%',
                            sm: '70%',
                            md: '60%',
                        },
                        maxHeight: '80%',
                        height: 'auto',
                        opacity: 1,
                        transition: 'opacity 0.5s ease, box-shadow 0.3s ease',
                        zIndex: 2,
                        borderRadius: '30px', // Increased curvature
                        overflow: 'hidden', // Ensures the video fits the border radius
                        '&:hover': {
                            boxShadow: '0 0 15px 5px #1E90FF', // Blue glow on hover
                        },
                    }}
                >
                    <video
                        src={LogoIcon}
                        autoPlay
                        muted
                        loop
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            border: 'none',
                            outline: 'none',
                        }}
                    />
                </Box>
            </Box>
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
                {words.map((word, index) => (
                    <div key={index} style={{ margin: '0 20px' }}> 
                        <div style={{ display: 'inline-flex' }}>
                            {renderLetters(word)}
                        </div>
                    </div>
                ))}
                <div style={{ height: '500px' }} />
            </div> */}


            {/* <Container sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'black' }}> */}
            {/* Mission Box */}


            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <AppBar
                    position="static"
                    sx={{
                        bgcolor: 'black',
                        borderRadius: '16px',
                        boxShadow: 'none',
                        width: isSmallScreen ? '70%' : '50%', // Responsive width
                        border: '2px solid transparent', // Default border color
                        transition: 'border-color 0.3s', // Smooth transition for border color
                        '&:hover': {
                            borderColor: '#1E90FF', // Change border color on hover
                        },
                    }}
                >
                    <Toolbar sx={{
                        justifyContent: 'center', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center', // Center align items
                        justifyContent: 'center', // Center buttons horizontally
                        flexWrap: 'wrap'
                    }}>
                        <Button color="inherit" onClick={() => scrollToSection('mission')} sx={{
                            '&:hover': {
                                color: '#1E90FF', // Change font color to blue on hover
                                mb: isSmallScreen ? 1 : 0, // Add margin-bottom for spacing in small screens
                                width: isSmallScreen ? '100%' : 'auto',
                            },
                        }}>Mission & Vision</Button>
                        <Button color="inherit" onClick={() => scrollToSection('features')} sx={{
                            '&:hover': {
                                color: '#1E90FF', // Change font color to blue on hover
                                mb: isSmallScreen ? 1 : 0, // Add margin-bottom for spacing in small screens
                                width: isSmallScreen ? '100%' : 'auto',
                            },
                        }}>Features</Button>
                        <Button color="inherit" onClick={() => scrollToSection('benefits')} sx={{
                            '&:hover': {
                                color: '#1E90FF', // Change font color to blue on hover
                                mb: isSmallScreen ? 1 : 0, // Add margin-bottom for spacing in small screens
                                width: isSmallScreen ? '100%' : 'auto',
                            },
                        }}>Benefits</Button>
                        <Button color="inherit" onClick={() => scrollToSection('ai')} sx={{
                            '&:hover': {
                                color: '#1E90FF', // Change font color to blue on hover
                            }, mb: isSmallScreen ? 1 : 0, // Add margin-bottom for spacing in small screens
                            width: isSmallScreen ? '100%' : 'auto',
                        }}>AI Sync</Button>
                        <Button color="inherit" onClick={() => scrollToSection('announcements')} sx={{
                            '&:hover': {
                                color: '#1E90FF', // Change font color to blue on hover
                            }, mb: isSmallScreen ? 1 : 0, // Add margin-bottom for spacing in small screens
                            width: isSmallScreen ? '100%' : 'auto',
                        }}>Announcements</Button>
                    </Toolbar>
                </AppBar>
            </Box>


            <Container
                sx={{
                    my: { xs: 4, sm: 8, md: 12 },
                    color: 'black',
                    maxWidth: '1000px',
                    margin: '0 auto',
                    width: '100%',
                    padding: { xs: 1, sm: 2, md: 3 },
                }}
            >
                <Slider {...sliderSettings} style={{ width: '100%', padding: 0, margin: 0 }}>
                    {/* Mission Box */}
                    <Box id="mission"
                        sx={{
                            backgroundImage: `url(${MissionBackground})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            padding: { xs: '6px', sm: '12px', md: '24px' },
                            color: 'white',
                            width: { xs: '50%', sm: '50%', md: '100%' },
                            height: { xs: '180px', sm: '250px', md: '400px' },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                            transition: 'border 0.3s ease',
                            margin: '0 auto',
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }} />
                        <Grid container spacing={2} sx={{ pb: { xs: '5px', sm: '10px' }, justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} sm={6} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3, mt: { xs: 2, sm: 4, md: 6, lg: 8 } }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontFamily: 'Helvetica, sans-serif',
                                        fontSize: {
                                            xs: '1.3rem',
                                            sm: '1.8rem',
                                            md: '2.2rem',
                                            lg: '2.6rem',    // Larger font size for large screens
                                            xl: '3rem'       // Extra-large font size for extra-large screens
                                        },
                                        // Increased font size for header
                                        mb: { xs: 0.5, sm: 1 },
                                        mt: { xs: 0.5, sm: 1 },
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Mission
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontFamily: 'Helvetica, sans-serif',
                                        fontSize: {
                                            xs: '0.9rem',
                                            sm: '1.1rem',
                                            md: '1.3rem',
                                            lg: '1.6rem',    // Larger font size for large screens
                                            xl: '1.8rem'     // Extra-large font size for extra-large screens
                                        },
                                        // Increased font size for body text
                                        textAlign: 'center',
                                    }}
                                >
                                    To provide a platform for every individual to access and share all kinds of knowledge.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Vision Box */}
                    <Box
                        sx={{
                            backgroundImage: `url(${VisionBackground})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            padding: { xs: '6px', sm: '12px', md: '24px' },
                            color: 'white',
                            width: { xs: '50%', sm: '50%', md: '100%' },
                            height: { xs: '180px', sm: '250px', md: '400px' },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                            transition: 'border 0.3s ease',
                            margin: '0 auto',
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }} />
                        <Grid container spacing={2} sx={{ pb: { xs: '5px', sm: '10px' }, justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} sm={6} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3, mt: { xs: 2, sm: 4, md: 6, lg: 8 } }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontFamily: 'Helvetica, sans-serif',
                                        fontSize: {
                                            xs: '1.3rem',
                                            sm: '1.8rem',
                                            md: '2.2rem',
                                            lg: '2.6rem',    // Larger font size for large screens
                                            xl: '3rem'       // Extra-large font size for extra-large screens
                                        }, // Increased font size for header
                                        mb: { xs: 0.5, sm: 1 },
                                        mt: { xs: 0.5, sm: 1 },
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Vision
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontFamily: 'Helvetica, sans-serif',
                                        fontSize: {
                                            xs: '0.9rem',
                                            sm: '1.1rem',
                                            md: '1.3rem',
                                            lg: '1.6rem',    // Larger font size for large screens
                                            xl: '1.8rem'     // Extra-large font size for extra-large screens
                                        }, // Increased font size for body text
                                        textAlign: 'center',
                                    }}
                                >
                                    To make every kind of knowledge accessible for everyone.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Slider>


            </Container>

            {/* To make every kind of knowledge accessible for everyone. */}
            <div style={{
                position: 'relative',
                minHeight: '100vh',
                overflowY: 'scroll',
                paddingTop: '10vh',
                display: 'flex',
                justifyContent: 'center', // Center horizontally
                alignItems: 'center', // Center vertically
                flexDirection: 'column',
            }}>
                <Container sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'black' }}>
                    <Container id="features"
                        sx={{
                            my: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'black',
                            width: '80%',
                            maxWidth: '800px',
                            margin: '0 auto',
                        }}
                    >
                        <Typography
                            variant="h4"
                            component="h4"
                            gutterBottom
                            sx={{
                                mb: 4,
                                fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
                                color: 'white',
                                textAlign: 'center',
                                marginTop: '50px',
                                fontSize: { xs: '28px', sm: '36px' }, // Responsive font size
                                '&:hover': {
                                    color: '#1E90FF', // Change font color to blue on hover
                                },
                            }}
                        >
                            Anthrasync Welcomes You!
                        </Typography>

                        {/* Grid for Features */}
                        <Grid container spacing={4} justifyContent="center">
                            {features.map((feature) => (
                                <Grid item key={feature.title} xs={12} sm={6} md={4}>
                                    <Card
                                        sx={{
                                            padding: '20px',
                                            borderRadius: '12px',
                                            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                                            textAlign: 'center',
                                            height: '400px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            backgroundColor: '#1C1C1C',
                                            color: 'white',
                                            border: '2px solid transparent',
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                                borderColor: '#1E90FF',
                                                boxShadow: '0 4px 15px rgba(0, 0, 255, 0.5)',
                                            },
                                            transition: 'transform 0.3s ease, border-color 0.3s ease',
                                        }}
                                    >
                                        {feature.image && (
                                            <img
                                                src={feature.image}
                                                alt={feature.title}
                                                loading="lazy"
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    objectFit: 'cover',
                                                    borderRadius: '8px',
                                                    marginBottom: '15px',
                                                }}
                                            />
                                        )}
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
                                                mt: 2,
                                                color: 'white',
                                                fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                                                textAlign: 'center',
                                                flexGrow: 0,
                                                '&:hover': {
                                                    color: '#1E90FF', // Change font color to blue on hover
                                                },
                                            }}
                                        >
                                            {feature.title}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
                                                color: 'white',
                                                flexGrow: 1,
                                                display: 'flex',
                                                justifyContent: 'left',
                                                alignItems: 'left',
                                                fontSize: { xs: '0.875rem', sm: '1rem' }, // Responsive font size
                                                textAlign: 'left',
                                                padding: '0 10px',
                                            }}
                                        >
                                            {feature.description}
                                        </Typography>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Container>
            </div>

            <Typography id="benefits"
                variant="h4"
                sx={{
                    mt: { xs: 10, sm: 15, md: 22 }, // Responsive top margin
                    mb: { xs: 4, sm: 6, md: 8 }, // Responsive bottom margin
                    fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
                    color: 'white',
                    textAlign: 'center',
                    fontSize: { xs: '24px', sm: '36px', md: '48px' }, // Responsive font size
                    '&:hover': {
                        color: '#1E90FF', // Change font color to blue on hover
                    },

                }}
            >
                Benefits
            </Typography>

            {/* Horizontal Sliding Benefit Boxes */}
            <Container sx={{ mb: 20, width: '80%', mx: 'auto' }}>
                <Grid container spacing={{ xs: 6, sm: 8, md: 10 }} justifyContent="center"> {/* Increased spacing for more gap */}
                    {benefits.map((benefit, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}> {/* Adjusted for larger screens */}
                            <Box
                                onMouseEnter={() => setActiveIndex(index)}
                                sx={{
                                    backgroundColor: '#1C1C1C',
                                    color: 'white',
                                    borderRadius: '8px',
                                    padding: { xs: '10px', sm: '15px', md: '20px' },
                                    boxShadow: activeIndex === index
                                        ? '0 0 15px 5px #1E90FF'
                                        : '0 4px 15px #1E90FF',
                                    textAlign: 'left',
                                    width: { xs: '90%', sm: '80%', md: '75%', lg: '95%', xl: '95%' }, // Increase width for larger screens
                                    height: { xs: 'auto', sm: 'auto', md: 'auto', lg: '90%', xl: '90%' },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    transition: 'all 0.3s ease',
                                    transform: activeIndex === index ? 'scale(1.05)' : 'scale(1)',
                                    position: 'relative',
                                    zIndex: activeIndex === index ? 1 : 0,
                                    margin: { xs: '5px auto', sm: '10px auto', md: '15px auto' }, // Centering with auto margin
                                }}
                            >
                                {/* Image */}
                                <img
                                    src={benefit.image}
                                    alt={`${benefit.title} Image`}
                                    style={{
                                        width: '100%',
                                        maxHeight: '120px',
                                        objectFit: 'cover',
                                        borderRadius: '8px',
                                        marginBottom: '20px',
                                    }}
                                />

                                {/* Title */}
                                <Typography variant="h6" sx={{
                                    fontFamily: 'Helvetica, sans-serif', mb: 1, textAlign: 'center',
                                    '&:hover': {
                                        color: '#1E90FF', // Change font color to blue on hover
                                    },
                                }}>
                                    {benefit.title}
                                </Typography>

                                {/* Description */}
                                <Typography variant="body2" sx={{ fontFamily: 'Helvetica, sans-serif', fontSize: '1rem', textAlign: 'left' }}>
                                    {benefit.description}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>


            <Container
                maxWidth="lg"
                disableGutters
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "80vh", // Reduced height
                    width: "90%", // Reduced width
                    backgroundImage: `url(${AI})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    color: "#fff",
                    textAlign: "center",
                    position: "relative",
                    overflow: "hidden",
                    mb: 20,
                    border: '2px solid transparent', // Default border
                    transition: 'border-color 0.3s ease',
                    '&:hover': {
                        borderColor: '#1E90FF', // Blue border on hover
                    },
                }}
            >
                {/* Overlay */}
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay for contrast
                        zIndex: 1,
                    }}
                ></Box>

                {/* Content */}
                <Box id="ai" sx={{ position: "relative", zIndex: 2, padding: 3, flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h2" sx={{
                        fontFamily: 'Helvetica, sans-serif', fontWeight: "bold", mb: 2,
                        '&:hover': {
                            color: '#1E90FF', // Change font color to blue on hover
                        },
                    }}>
                        AI Sync
                    </Typography>
                    <Typography variant="h5" sx={{
                        fontFamily: 'Helvetica, sans-serif', mb: 4, '&:hover': {
                            color: '#1E90FF', // Change font color to blue on hover
                        },
                    }}>
                        Experience AI Like Never Before.
                    </Typography>
                </Box>

                {/* Button at the bottom */}
                {/* Uncomment if you want to add a button */}
                {/* <Box sx={{ padding: 3, mb: 2, position: 'relative', zIndex: 2 }}>
                <Link to="/ai-content" style={{ textDecoration: 'none' }}>
                    <Box
                        component="span"
                        sx={{
                            display: "inline-block",
                            backgroundColor: "#fff",
                            color: "#000",
                            fontWeight: "bold",
                            padding: "10px 30px",
                            borderRadius: "25px",
                            ":hover": { backgroundColor: "#ddd" },
                            cursor: "pointer",
                        }}
                    >
                        Learn more
                    </Box>
                </Link>
            </Box> */}
            </Container>








            {/* Announcement Section */}
            <Box id="announcements"
                onMouseEnter={() => setActiveIndex(-1)} // Optional: deselect benefits when hovering over announcements
                sx={{
                    backgroundColor: '#1C1C1C',
                    color: 'white',
                    borderRadius: '12px',
                    padding: { xs: '10px', sm: '15px', md: '20px' },
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
                    textAlign: 'center',
                    width: { xs: '90%', sm: '70%', md: '50%' },
                    maxWidth: '800px',
                    margin: '20px auto',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    height: { xs: 'auto', sm: '450px', md: '500px' },
                    mb: { xs: 4, sm: 6, md: 8 }, // Additional bottom margin for spacing
                    '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 0 20px #1E90FF',
                    },
                }}
            >
                {/* Image at the upper part of the box */}
                <img
                    src={Announcement} // Replace with your image path
                    alt="Announcement"
                    style={{
                        width: '100%',
                        maxHeight: '180px',
                        objectFit: 'cover',
                        borderRadius: '8px',
                        marginBottom: '10px',
                    }}
                />

                {/* Text content at the lower side of the box */}
                <Box
                    sx={{
                        textAlign: 'center',
                        width: '100%',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h4"
                        component="h4"
                        gutterBottom
                        sx={{
                            fontFamily: 'Helvetica, sans-serif',
                            mb: { xs: 1, sm: 2 },
                            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' },
                            '&:hover': {
                                color: '#1E90FF', // Change font color to blue on hover
                            },

                        }}
                    >
                        Announcements
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontFamily: 'Helvetica, sans-serif',
                            mt: { xs: '8px', sm: '10px' },
                            fontSize: { xs: '0.9rem', sm: '1rem' },
                            textAlign: 'center',
                        }}
                    >
                        <strong>Exciting updates ahead!</strong>
                        <p style={{ fontSize: '1rem', margin: '10px 0', textAlign: 'center', fontFamily: 'Helvetica, sans-serif' }}>
                            New features are coming soon! Stay tuned.
                        </p>
                        <p style={{ fontSize: '1rem', textAlign: 'center', fontFamily: 'Helvetica, sans-serif' }}>
                            We can’t wait to share what’s next!
                        </p>
                    </Typography>
                </Box>
            </Box>

            <IconButton
                onClick={scrollToTop}
                sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}>
                <ArrowUpwardIcon />
            </IconButton>

            <Footer />
        </div >
    );
}

export default Home;
